import { DatePicker, Modal, Select, Spin, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SubsidiaryCompanyAPI from '../../subsidiaryCompany/SubsidiaryCompanyAPI';
import SubsidiaryCompany from '../../subsidiaryCompany/types/SubsidiaryCompany';
import colors from '../../theme/colors';
import InsightsAPI from '../InsightsAPI';
import { CourseChartData, CourseChartSortOrder } from '../types/Graph';

import CourseDoughnutChart, { ChartOption } from './CourseDoughnutChart';

type CourseInsightModalProps = {
  visible?: boolean;
  setVisible?: (value: boolean) => void;
  subsidiaryCompanyId?: number;
};

const { RangePicker } = DatePicker;

const CourseInsightModal: React.FC<CourseInsightModalProps> = ({
  visible,
  setVisible,
  subsidiaryCompanyId = -1,
}) => {
  const [pickerDates, setPickerDates] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([
    dayjs().subtract(29, 'day'),
    dayjs(),
  ]);
  const [courseListData, setCourseListData] = useState<CourseChartData[]>();
  const [courseChartData, setCourseChartData] = useState<CourseChartData[]>();
  const [loadingChartData, setLoadingChartData] = useState(false);
  const [subsidiaryCompanies, setSubsidiaryCompanies] =
    useState<SubsidiaryCompany[]>();
  const [selectedSubsidiaryCompanyId, setSelectedSubsidiaryCompanyId] =
    useState(subsidiaryCompanyId);
  const [chartOption, setChartOption] = useState<ChartOption>(ChartOption.ALL);
  const [loadingSubsidiaryCompanies, setLoadingSubsidiaryCompanies] =
    useState(false);

  const displayedChartElementsCount = 10;

  const { t } = useTranslation();

  const columns: ColumnsType<CourseChartData> = [
    {
      title: t('components.CourseInsightModal.courseName'),
      className: 'text-gray-700',
      dataIndex: 'courseName',
      key: 'courseName',
      width: '60%',
      render: (courseName: string, _, index: number) => (
        <div className="flex flex-row items-center">
          <div
            className="h-8 w-8 rounded-full mr-4"
            style={{
              backgroundColor: Object.values(colors.doughnutChart)[
                Math.min(index, Object.values(colors.doughnutChart).length - 1)
              ],
            }}
          />
          <div className="font-semibold text-black">{courseName}</div>
        </div>
      ),
    },
    {
      title: `# ${t('common.instructors')}`,
      className: 'text-gray-700',
      dataIndex: 'instructorCount',
      key: 'instructorCount',
    },
    {
      title: `# ${t('common.courseInstances')}`,
      className: 'font-medium',
      dataIndex: 'courseInstanceCount',
      key: 'courseInstanceCount',
    },
  ];

  const fetchCourseData = useCallback(async () => {
    setLoadingChartData(true);

    const sortOrder =
      chartOption === ChartOption.BOTTOM_TEN
        ? CourseChartSortOrder.ASCENDING
        : CourseChartSortOrder.DESCENDING;

    try {
      const { data } = await InsightsAPI.getCourseChartData(
        sortOrder,
        pickerDates[0].format('YYYY-MM-DD'),
        pickerDates[1].format('YYYY-MM-DD'),
        selectedSubsidiaryCompanyId !== -1
          ? selectedSubsidiaryCompanyId
          : undefined,
      );

      const displayedChartCourses =
        data.length > displayedChartElementsCount
          ? data.slice(0, displayedChartElementsCount)
          : data;

      if (
        data.length > displayedChartElementsCount &&
        chartOption === ChartOption.ALL
      ) {
        displayedChartCourses.push({
          courseId: 0,
          courseName: t('common.other'),
          instructorCount: 0,
          courseInstanceCount: data
            .slice(displayedChartElementsCount)
            .map((chartData) => chartData.courseInstanceCount)
            .reduce((a, b) => a + b),
        });
      }

      setCourseChartData(displayedChartCourses);
      setCourseListData(data);
    } finally {
      setLoadingChartData(false);
    }
  }, [chartOption, pickerDates, selectedSubsidiaryCompanyId, t]);

  const fetchSubsidiaryCompanies = useCallback(async () => {
    setLoadingSubsidiaryCompanies(true);
    try {
      const { data } = await SubsidiaryCompanyAPI.getSubsidiaryCompanies();
      setSubsidiaryCompanies(data);
    } finally {
      setLoadingSubsidiaryCompanies(false);
    }
  }, []);

  useEffect(() => {
    if (!loadingSubsidiaryCompanies) {
      fetchCourseData();
    }
  }, [loadingSubsidiaryCompanies, fetchCourseData]);

  useEffect(() => {
    setSelectedSubsidiaryCompanyId(subsidiaryCompanyId);
  }, [subsidiaryCompanyId, visible]);

  useEffect(() => {
    fetchSubsidiaryCompanies();
  }, [fetchSubsidiaryCompanies]);

  const IntervalPicker: React.FC = () => {
    return (
      <div>
        <div className="text-grayDark text-xs">
          {t('components.CourseInsightModal.specificDates')}
        </div>
        <RangePicker
          picker={'date'}
          defaultValue={pickerDates}
          disabledDate={(date) =>
            !!pickerDates.find((pickerDate) => pickerDate.isSame(date, 'date'))
          }
          allowClear={false}
          onCalendarChange={(dates) => {
            if (dates && dates[0] && dates[1]) {
              setPickerDates([dates[0], dates[1]]);
            }
          }}
        />
      </div>
    );
  };

  const ChartOptionPicker: React.FC = () => {
    return (
      <div className="pt-4">
        <div className="text-grayDark text-base pb-1">
          {t('components.CourseInsightModal.chartOptions')}
        </div>
        <Select
          size="large"
          style={{ minWidth: '256px' }}
          onChange={(value: ChartOption) => setChartOption(value)}
          value={chartOption}>
          <Select.Option key={0} value={ChartOption.ALL}>
            {t('components.CourseInsightModal.showAllCourses')}
          </Select.Option>
          <Select.Option key={1} value={ChartOption.TOP_TEN}>
            {t('components.CourseInsightModal.mostBookedCourses')}
          </Select.Option>
          <Select.Option key={2} value={ChartOption.BOTTOM_TEN}>
            {t('components.CourseInsightModal.leastBookedCourses')}
          </Select.Option>
        </Select>
      </div>
    );
  };

  const SubsidiaryCompanyPicker: React.FC = () => {
    return (
      <div className="pr-8">
        <div className="text-grayDark text-xs">{t('common.companies')}</div>
        <Select
          className="modal-subsidiary-selector max-w-lg h-8"
          style={{ minWidth: '128px' }}
          onChange={(value: number) => setSelectedSubsidiaryCompanyId(value)}
          loading={loadingSubsidiaryCompanies}
          disabled={loadingSubsidiaryCompanies}
          defaultValue={selectedSubsidiaryCompanyId}>
          <Select.Option key={-1} value={-1}>
            {t('components.CourseInsightModal.allCompanies')}
          </Select.Option>
          {subsidiaryCompanies?.map((subsidiaryCompany, key) => {
            return (
              <Select.Option key={key} value={subsidiaryCompany.id}>
                <div>{subsidiaryCompany.name}</div>
              </Select.Option>
            );
          })}
        </Select>
      </div>
    );
  };

  return (
    <>
      <Modal
        width={900}
        open={visible}
        onCancel={() => setVisible?.(false)}
        title={
          <div className="flex flex-row items-center h-8">
            <span className="text-xl font-medium">
              {t('components.CourseChartCard.courseStatistics')}
            </span>
            {loadingChartData && <Spin className="ml-2" />}
          </div>
        }
        bodyStyle={{ paddingTop: '0px' }}
        footer={null}>
        <div className="-mx-6">
          <div className="bg-beige py-5 px-8 flex flex-row items-start">
            <SubsidiaryCompanyPicker />
            <IntervalPicker />
          </div>
        </div>
        <div className="my-3 pt-4">
          <div className="mx-2 pb-8">
            {courseListData?.length && (
              <div className="text-lg font-semibold">
                {t('components.CourseInsightModal.displayingCourses', {
                  count: courseListData?.length,
                })}
              </div>
            )}
            <div className="flex justify-between">
              <ChartOptionPicker />
              <CourseDoughnutChart
                className="mr-32"
                chartOption={chartOption}
                courseChartData={courseChartData}
                size="large"
              />
            </div>
          </div>
          <Table
            loading={loadingChartData}
            className="course-chart-table"
            pagination={false}
            columns={columns}
            dataSource={courseListData}
          />
        </div>
      </Modal>
    </>
  );
};

export default CourseInsightModal;
