import React from 'react';
import { Col, Row, Tooltip } from 'antd';
import { twMerge } from 'tailwind-merge';
import { useInsights } from '../../context/insights';
import { useTranslation } from 'react-i18next';

type StatisticCardProps = {
  title: string;
  tooltipTitle?: React.ReactNode;
  value?: number;
  icon?: React.ReactNode;
  svg?: string;
  unit?: string;
  average?: boolean;
  selected?: boolean;
  setSelectedOnHover?: () => void;
  loading: boolean;
};

const StatisticCard: React.FC<StatisticCardProps> = ({
  title,
  tooltipTitle,
  value,
  icon,
  svg,
  unit,
  average,
  selected,
  setSelectedOnHover,
  loading,
}) => {
  const { t } = useTranslation();
  const { setSelectedStatus } = useInsights();

  return (
    <Tooltip overlayStyle={{ maxWidth: 'none' }} title={tooltipTitle}>
      <div
        className={twMerge(
          'flex-1 h-full p-[12px] border border-solid border-[#f0f0f0] rounded-lg',
          selected && 'shadow-lg border border-safeLifeMedium',
          setSelectedOnHover !== undefined && 'cursor-pointer',
        )}
        onMouseEnter={setSelectedOnHover}
        onMouseLeave={() => setSelectedStatus(null)}>
        <Col className="flex flex-col gap-2 h-full">
          <Row>{icon ?? (svg && <img src={svg} />)}</Row>

          <Row className="text-xs font-semibold gap-1">
            {title}
            {average && (
              <span className="text-grayMedium font-thin">
                {t('views.InsightsV2.average')}
              </span>
            )}
          </Row>
          <Row className="text-2xl font-semibold leading-4 mt-auto">
            {value !== undefined && value !== null && !loading
              ? `${value} ${unit ?? ''}`
              : '-'}
          </Row>
        </Col>
      </div>
    </Tooltip>
  );
};

export default StatisticCard;
