import React, { useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { Chart as ChartType, TooltipModel } from 'chart.js';
import { useTranslation } from 'react-i18next';

import { CourseChartData } from '../types/Graph';
import colors from '../../theme/colors';

export enum ChartOption {
  TOP_TEN,
  BOTTOM_TEN,
  ALL,
}

const chartSizeStyling = {
  medium: {
    chartSizeClassName: 'h-54 w-54',
    chartOptionClassName: 'text-xs -mt-8',
    countClassName: 'text-2xl',
    courseInstancesClassName: 'text-xs',
    cutout: 75,
  },
  large: {
    chartSizeClassName: 'h-72 w-72',
    chartOptionClassName: 'text-base -mt-[3.4rem]',
    countClassName: 'text-5xl -mt-[0.6rem]',
    courseInstancesClassName: 'text-sm -mt-2',
    cutout: 100,
  },
};

type CourseDoughnutChartProps = {
  courseChartData?: CourseChartData[];
  chartOption: ChartOption;
  className?: string;
  size?: 'medium' | 'large';
};

export type Tooltip = [string, number];

const CourseDoughnutChart: React.FC<CourseDoughnutChartProps> = ({
  courseChartData,
  chartOption,
  className = '',
  size = 'medium',
}) => {
  const { t } = useTranslation();
  const [[tooltipTitle, tooltipCourseInstanceCount], setTooltip] =
    useState<Tooltip>(['', 0]);
  const [tooltipStyle, setTooltipStyle] = useState<React.CSSProperties>({});
  const [lastIndex, setLastIndex] = useState<number>();
  const [lastOpacity, setLastOpacity] = useState<number>();

  const getChartOptionText = (chartOption: ChartOption) => {
    switch (chartOption) {
      case ChartOption.ALL:
        return t('components.CourseInsightModal.allCourses');
      case ChartOption.TOP_TEN:
        return t('components.CourseInsightModal.tenMostBooked');
      case ChartOption.BOTTOM_TEN:
        return t('components.CourseInsightModal.tenLeastBooked');
    }
  };

  const totalCourseInstanceCount =
    courseChartData
      ?.map((chartData) => chartData.courseInstanceCount)
      .reduce((a, b) => a + b, 0) ?? 0;

  const doughnutChartData = {
    labels: courseChartData?.map((chartData) => chartData.courseName),
    datasets: [
      {
        // Set minimum doughnut segment size to 1% and handle cases where total course instance count is 0
        data: courseChartData?.map((chartData) =>
          Math.max(
            (chartData.courseInstanceCount /
              (totalCourseInstanceCount !== 0 ? totalCourseInstanceCount : 1)) *
              100,
            1,
          ),
        ),
        backgroundColor: Object.values(colors.doughnutChart),
        borderColor: colors.offwhite,
      },
    ],
  };

  const setTooltipData = (context: {
    tooltip: TooltipModel<'doughnut'>;
    chart: ChartType;
  }) => {
    if (!context.tooltip?.dataPoints?.length || !courseChartData) return;

    const index = context.tooltip.dataPoints[0].dataIndex;
    const opacity = context.tooltip.opacity ? 1 : 0;

    if (index === lastIndex && opacity === lastOpacity) return;

    setLastIndex(index);
    setLastOpacity(opacity);

    const dataPoint = context.tooltip.dataPoints[0];
    setTooltipStyle({
      opacity,
      left: `${context.chart.canvas.offsetLeft + context.tooltip.caretX}px`,
      top: `${context.chart.canvas.offsetTop + context.tooltip.caretY}px`,
    });

    setTooltip([
      dataPoint.label,
      courseChartData[dataPoint.dataIndex].courseInstanceCount,
    ]);
  };

  return (
    <>
      {doughnutChartData && (
        <div
          className={`${chartSizeStyling[size].chartSizeClassName} relative ${className}`}>
          <div
            className="rounded-lg p-2 absolute pointer-events-none text-white whitespace-nowrap	"
            style={{
              backgroundColor: colors.safeLifeDarker,
              transform: 'translate(-50%, -130%)',
              opacity: 0,
              zIndex: 10,
              transition: 'all .1s ease',
              ...tooltipStyle,
            }}>
            <div className="font-medium">{tooltipTitle}</div>
            <div>
              {tooltipCourseInstanceCount}{' '}
              {t('components.CourseInsightModal.courseInstance', {
                count: tooltipCourseInstanceCount,
              })}
            </div>
          </div>
          <Chart
            className="absolute"
            type="doughnut"
            data={doughnutChartData}
            options={{
              plugins: {
                tooltip: {
                  enabled: false,
                  external: setTooltipData,
                },
              },
              cutout: chartSizeStyling[size].cutout,
            }}
          />
          <div
            className={`z-0 absolute top-[50%] left-0 right-0 text-center font-medium pointer-events-none w-100% ${size === 'medium' && '-mt-2'}`}>
            <div className={chartSizeStyling[size].chartOptionClassName}>
              {getChartOptionText(chartOption)}
            </div>
            <div className={chartSizeStyling[size].countClassName}>
              {totalCourseInstanceCount}
            </div>
            <div
              className={`${chartSizeStyling[size].courseInstancesClassName} text-gray-600`}>
              {t('components.CourseInsightModal.courseInstance', {
                count: totalCourseInstanceCount,
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CourseDoughnutChart;
