import ApiResponse from '../types/ApiResponse';
import ClientCompany from '../bookingOrders/types/ClientCompany';
import PaginationMeta from '../types/PaginationMeta';
import PaginationParams from '../types/PaginationParams';
import SubsidiaryCompany from '../subsidiaryCompany/types/SubsidiaryCompany';
import User from '../user/types/User';
import client from '../api/client';

import CourseInstance, {
  CalendarCourseInstanceInfo,
  CourseInstanceCardData,
  CourseInstanceHeatMapData,
  CourseInstanceStatus,
  InstructorCourseInstance,
  CourseInstanceAttendee,
  CourseInstanceAttendeeComplete,
  CourseInstanceAttendeeStatus,
} from './types/CourseInstance';

export type CourseInstanceApiFilter = {
  orderIds?: number[];
  instructorIds?: User['id'][];
  subsidiaryCompanyIds?: SubsidiaryCompany['id'][];
  clientCompanyIds?: ClientCompany['id'][];
  courseIds?: number[];
  creatorIds?: User['id'][];
  startsAt?: string;
  endsAt?: string;
  status?: CourseInstanceStatus[];
  completed?: boolean;
  month?: string;
  hideCourseInstancesWithPrice?: boolean;
};

export type CreateCourseInstanceParams = {
  courseId: number;
  orderId: number;
  startsAt?: string;
  endsAt?: string;
  contactPersonId?: number;
  instructorId?: number | null;
  price: number;
  currencyId: number;
  address?: string;
  placeId?: string;
  lat?: number;
  lng?: number;
  comment?: string;
  equipment?: string;
  mileageQuantity?: number;
  mileagePrice?: number;
  participantLimit?: number;
  managerEmails?: string[];
};

type UpdateCourseInstanceParams = {
  courseId: number;
  startsAt?: string;
  endsAt?: string;
  contactPersonId?: number;
  instructorId?: number | null;
  canceled?: boolean;
  confirmed?: boolean;
  price: number;
  currencyId: number;
  address?: string;
  placeId?: string;
  lat?: number;
  lng?: number;
  comment?: string;
  equipment?: string;
  mileageQuantity?: number;
  mileagePrice?: number;
  participantLimit?: number;
  managerEmails?: string[];
};

type SendCourseInstanceToElsaParams = {
  participantLimit?: number;
  managerEmails?: string[];
};

export type ValidateCourseInstanceParams = {
  courseId: number;
  currencyId: number;
  orderId?: number;
  contactPersonId?: number;
  instructorId?: number | null;
  startsAt?: string;
  endsAt?: string;
  lat?: number;
  lng?: number;
  confirmed?: boolean;
  mileagePrice?: number;
};

export type ChangeCourseInstanceInstructorParams = {
  instructorId: number | null;
};

export type ChangeCourseInstanceCourseParams = {
  courseId: number;
};

export type SetCanceledParams = {
  canceled: boolean;
};

export type SetConfirmedParams = {
  confirmed: boolean;
};

export type SwapInstructorParams = {
  secondCourseInstanceId: number;
};

export type GetCourseInstanceHeatMapParams = {
  southWestLat: number;
  southWestLng: number;
  northEastLat: number;
  northEastLng: number;
  from?: string;
  to?: string;
};

export type CreateAttendeeParams = {
  name: string;
  email?: string;
  status?: CourseInstanceAttendeeStatus;
  comment?: string;
};

export type UpdateAttendeeParams = {
  id?: number;
  elsaId?: string;
  name: string;
  email?: string;
  status?: CourseInstanceAttendeeStatus;
  comment?: string;
};

export type DeleteAttendeeParams = {
  id?: number;
  elsaId?: string;
};

const getCourseInstances = (
  filter?: CourseInstanceApiFilter,
  paginationParams?: PaginationParams | null,
) =>
  client.get<ApiResponse<CourseInstanceCardData[], PaginationMeta>>(
    '/course-instances',
    {
      params: {
        ...filter,
        ...paginationParams,
      },
    },
  );

const getCourseInstanceCalendar = (filter?: CourseInstanceApiFilter) =>
  client.get<CalendarCourseInstanceInfo[]>('/course-instances/calendar', {
    params: filter,
  });

const validateCourseInstance = (
  validateParams: ValidateCourseInstanceParams,
  courseInstanceId: number,
) =>
  client.patch<string[]>(
    `/course-instances/${courseInstanceId}/validate`,
    validateParams,
  );

const validateCourseInstanceParameters = (
  validateParams: ValidateCourseInstanceParams,
) => client.patch<string[]>('/course-instances/validate', validateParams);

const getCourseInstance = (id: CourseInstance['id']) =>
  client.get<CourseInstance>(`/course-instances/${id}`);

const getInstructorCourseInstance = (id: CourseInstance['id']) =>
  client.get<InstructorCourseInstance>(
    `/course-instances/instructor-course-instance/${id}`,
  );

const createCourseInstance = (params: CreateCourseInstanceParams) =>
  client.post<CourseInstance>('/course-instances', params);

const sendCourseInstanceToElsa = (
  id: CourseInstance['id'],
  params?: SendCourseInstanceToElsaParams,
) =>
  client.post<CourseInstance>(
    `course-instances/${id}/send-to-elsa`,
    params ?? {},
  );

const updateCourseInstance = (
  id: CourseInstance['id'],
  params: UpdateCourseInstanceParams,
) => client.patch<CourseInstance>(`/course-instances/${id}`, params);

const deleteCourseInstance = (id: CourseInstance['id']) =>
  client.delete<CourseInstance>(`/course-instances/${id}`);

const restoreCourseInstance = (id: CourseInstance['id']) =>
  client.post<CourseInstance>(`/course-instances/${id}/restore`);

const changeCourseInstanceInstructor = (
  id: CourseInstance['id'],
  params: ChangeCourseInstanceInstructorParams,
) =>
  client.patch<CourseInstanceCardData>(
    `/course-instances/${id}/instructor`,
    params,
  );

const changeCourseInstanceCourse = (
  id: CourseInstance['id'],
  params: ChangeCourseInstanceCourseParams,
) =>
  client.patch<CourseInstanceCardData>(
    `/course-instances/${id}/course`,
    params,
  );

const setCanceled = (id: CourseInstance['id'], params: SetCanceledParams) =>
  client.patch(`/course-instances/${id}/canceledStatus`, params);

const setConfirmed = (id: CourseInstance['id'], params: SetConfirmedParams) =>
  client.patch(`/course-instances/${id}/confirmedStatus`, params);

const createAttendees = (
  id: CourseInstance['id'],
  attendees: CreateAttendeeParams[],
) =>
  client.post<CourseInstanceAttendeeComplete[]>(
    `/course-instances/${id}/attendees`,
    { attendees },
  );

const getAttendees = (id: CourseInstance['id']) =>
  client.get<CourseInstanceAttendee[]>(`/course-instances/${id}/attendees`);

const updateAttendees = (
  id: CourseInstance['id'],
  attendees: UpdateAttendeeParams[],
) =>
  client.patch<CourseInstanceAttendeeComplete[]>(
    `/course-instances/${id}/attendees`,
    {
      attendees,
    },
  );

const deleteAttendees = (
  id: CourseInstance['id'],
  attendees: DeleteAttendeeParams[],
) =>
  client.delete(`/course-instances/${id}/attendees`, {
    data: { attendees: attendees },
  });

const swapInstructors = (
  id: CourseInstance['id'],
  params: SwapInstructorParams,
) =>
  client.patch<CourseInstanceCardData>(
    `/course-instances/${id}/swap-instructor`,
    params,
  );

const getCourseInstanceHeatMap = (params?: GetCourseInstanceHeatMapParams) =>
  client.get<CourseInstanceHeatMapData[]>('/course-instances/heat-map', {
    params,
  });

export default {
  getCourseInstances,
  getCourseInstanceCalendar,
  validateCourseInstance,
  validateCourseInstanceParameters,
  getCourseInstance,
  getInstructorCourseInstance,
  createCourseInstance,
  updateCourseInstance,
  deleteCourseInstance,
  restoreCourseInstance,
  changeCourseInstanceInstructor,
  changeCourseInstanceCourse,
  setConfirmed,
  setCanceled,
  swapInstructors,
  getCourseInstanceHeatMap,
  createAttendees,
  getAttendees,
  deleteAttendees,
  updateAttendees,
  sendCourseInstanceToElsa,
};
