import React from 'react';
import { Dayjs } from 'dayjs';
import {
  CourseInstanceLeadTime,
  CourseInstanceLeadTimeStatistic,
  CourseInstanceLeadTimeXMode,
  CourseInstanceLeadTimeYMode,
} from '../types/CourseInstanceLeadTime';
import {
  CourseInstanceStatus,
  CourseInstanceStatusStatistic,
  CourseInstanceStatusXMode,
  CourseInstanceStatusYMode,
} from '../types/CourseInstanceStatus';
import { t } from 'i18next';
import { BucketSizeOption } from '../types/InsightsFilters';
import { Trans } from 'react-i18next';

export const translateCourseInstanceStatus = (
  status: CourseInstanceStatus,
): string => {
  switch (status) {
    case CourseInstanceStatus.TOTAL:
      return t('components.CourseInstanceStatusInsights.Type.total');
    case CourseInstanceStatus.PRELIMINARY:
      return t('components.CourseInstanceStatusInsights.Type.preliminary');
    case CourseInstanceStatus.CONFIRMED:
      return t('components.CourseInstanceStatusInsights.Type.confirmed');
    case CourseInstanceStatus.INVOICED:
      return t('components.CourseInstanceStatusInsights.Type.invoiced');
    case CourseInstanceStatus.CANCELED:
      return t('components.CourseInstanceStatusInsights.Type.canceled');
  }
};

export const translateGraphCourseInstanceStatus = (
  status: CourseInstanceStatus,
  yMode: CourseInstanceStatusYMode,
) => {
  switch (status) {
    case CourseInstanceStatus.TOTAL:
      switch (yMode) {
        case CourseInstanceStatusYMode.NUMBER_OF_CLASSES:
          return t('components.CourseInstanceStatusInsights.Type.totalClasses');
        case CourseInstanceStatusYMode.REVENUE:
          return t('components.CourseInstanceStatusInsights.Type.totalRevenue');
      }
    case CourseInstanceStatus.PRELIMINARY:
      return t('components.CourseInstanceStatusInsights.Type.preliminary');
    case CourseInstanceStatus.CONFIRMED:
      return t('components.CourseInstanceStatusInsights.Type.confirmed');
    case CourseInstanceStatus.INVOICED:
      return t('components.CourseInstanceStatusInsights.Type.invoiced');
    case CourseInstanceStatus.CANCELED:
      return t('components.CourseInstanceStatusInsights.Type.canceled');
  }
};

export const translateCourseInstanceStatusStatistic = (
  statusStatistic: CourseInstanceStatusStatistic,
): string => {
  switch (statusStatistic) {
    case CourseInstanceStatusStatistic.TOTAL:
      return t('components.CourseInstanceStatusInsights.Statistics.total');
    case CourseInstanceStatusStatistic.PRELIMINARY:
      return t(
        'components.CourseInstanceStatusInsights.Statistics.preliminary',
      );
    case CourseInstanceStatusStatistic.CONFIRMED:
      return t('components.CourseInstanceStatusInsights.Statistics.confirmed');
    case CourseInstanceStatusStatistic.INVOICED:
      return t('components.CourseInstanceStatusInsights.Statistics.invoiced');
    case CourseInstanceStatusStatistic.WITHOUT_PRICE:
      return t(
        'components.CourseInstanceStatusInsights.Statistics.withoutPrice',
      );
    case CourseInstanceStatusStatistic.CANCELED:
      return t('components.CourseInstanceStatusInsights.Statistics.canceled');
  }
};

const formatTooltipDate = (dayjs: Dayjs) => {
  return dayjs.format('YYYY-MM-DD');
};

export const getCourseInstanceStatusStatisticTooltipTitle = (
  statusStatistic: CourseInstanceStatusStatistic,
  courseInstanceStatusXMode: CourseInstanceStatusXMode,
  classCount: number,
  fromDate: Dayjs,
  toDate: Dayjs,
) => {
  let statisticTypeRow: string;
  let dateRow: string;
  let currentClassStatus: string;
  let extraInformationRow: string | undefined;
  const numberOfClassesRow = t(
    'components.CourseInstanceStatusInsights.Statistics.numberOfClasses',
    { count: classCount },
  );

  switch (statusStatistic) {
    case CourseInstanceStatusStatistic.TOTAL:
      statisticTypeRow = t(
        'components.CourseInstanceStatusInsights.Statistics.total',
      );
      currentClassStatus = t(
        'components.CourseInstanceStatusInsights.Statistics.any',
      );
      break;
    case CourseInstanceStatusStatistic.PRELIMINARY:
      statisticTypeRow = t(
        'components.CourseInstanceStatusInsights.Statistics.preliminary',
      );
      currentClassStatus = t(
        'components.CourseInstanceStatusInsights.Statistics.preliminary',
      );
      break;

    case CourseInstanceStatusStatistic.CONFIRMED:
      statisticTypeRow = t(
        'components.CourseInstanceStatusInsights.Statistics.confirmed',
      );
      currentClassStatus = t(
        'components.CourseInstanceStatusInsights.Statistics.confirmed',
      );
      break;

    case CourseInstanceStatusStatistic.INVOICED:
      statisticTypeRow = t(
        'components.CourseInstanceStatusInsights.Statistics.invoiced',
      );
      currentClassStatus = t(
        'components.CourseInstanceStatusInsights.Statistics.any',
      );
      extraInformationRow = t(
        'components.CourseInstanceStatusInsights.Statistics.orderInvoiced',
      );
      break;

    case CourseInstanceStatusStatistic.WITHOUT_PRICE:
      statisticTypeRow = t(
        'components.CourseInstanceStatusInsights.Statistics.withoutPrice',
      );
      currentClassStatus = t(
        'components.CourseInstanceStatusInsights.Statistics.any',
      );
      extraInformationRow = t(
        'components.CourseInstanceStatusInsights.Statistics.classHasNoPrice',
      );
      break;

    case CourseInstanceStatusStatistic.CANCELED:
      statisticTypeRow = t(
        'components.CourseInstanceStatusInsights.Statistics.canceled',
      );
      currentClassStatus = t(
        'components.CourseInstanceStatusInsights.Statistics.canceled',
      );

      break;
  }

  switch (courseInstanceStatusXMode) {
    case CourseInstanceStatusXMode.BOOKING_CREATED:
      dateRow = t(
        'components.CourseInstanceStatusInsights.Statistics.classSoldBetweenDates',
        {
          fromDate: formatTooltipDate(fromDate),
          toDate: formatTooltipDate(toDate),
        },
      );
      break;
    case CourseInstanceStatusXMode.COURSE_INSTANCE_DATE:
      dateRow = t(
        'components.CourseInstanceStatusInsights.Statistics.classScheduledBetweenDates',
        {
          fromDate: formatTooltipDate(fromDate),
          toDate: formatTooltipDate(toDate),
        },
      );
      break;
  }

  return (
    <div>
      <div className="font-bold">{statisticTypeRow}</div>
      <div>{dateRow}</div>
      <div>
        {t(
          'components.CourseInstanceStatusInsights.Statistics.classCurrentStatus',
          {
            statusName: currentClassStatus,
          },
        )}
      </div>
      {extraInformationRow && <div>{extraInformationRow}</div>}
      <div className="mt-2">{numberOfClassesRow}</div>
    </div>
  );
};

export const translateCourseInstanceLeadTime = (
  leadTime: CourseInstanceLeadTime,
): string => {
  switch (leadTime) {
    case CourseInstanceLeadTime.CREATED:
      return t('components.CourseInstanceLeadTimeInsights.Type.created');
    case CourseInstanceLeadTime.CONFIRMED:
      return t('components.CourseInstanceLeadTimeInsights.Type.confirmed');
    case CourseInstanceLeadTime.INVOICED:
      return t('components.CourseInstanceLeadTimeInsights.Type.invoiced');
  }
};

export const translateCourseInstanceLeadTimeStatistic = (
  leadTimeStatistic: CourseInstanceLeadTimeStatistic,
): string => {
  switch (leadTimeStatistic) {
    case CourseInstanceLeadTimeStatistic.AVERAGE_DAYS_TO_CONFIRMED:
      return t(
        'components.CourseInstanceLeadTimeInsights.Statistics.timeToConfirmed',
      );
    case CourseInstanceLeadTimeStatistic.AVERAGE_DAYS_TO_COMPLETED:
      return t(
        'components.CourseInstanceLeadTimeInsights.Statistics.timeToCompleted',
      );
    case CourseInstanceLeadTimeStatistic.AVERAGE_DAYS_CONFIRMED_TO_INVOICED:
      return t(
        'components.CourseInstanceLeadTimeInsights.Statistics.timeConfirmedToInvoiced',
      );
    case CourseInstanceLeadTimeStatistic.NOT_INVOICED:
      return t(
        'components.CourseInstanceLeadTimeInsights.Statistics.notInvoiced',
      );
    case CourseInstanceLeadTimeStatistic.INVOICED_IN_OTHER_MONTH:
      return t(
        'components.CourseInstanceLeadTimeInsights.Statistics.invoicedInOtherMonth',
      );
  }
};

export const translateCourseInstanceStatusXMode = (
  xMode: CourseInstanceStatusXMode,
): string => {
  switch (xMode) {
    case CourseInstanceStatusXMode.BOOKING_CREATED:
      return t('components.CourseInstanceStatusInsights.xMode.booking_created');
    case CourseInstanceStatusXMode.COURSE_INSTANCE_DATE:
      return t(
        'components.CourseInstanceStatusInsights.xMode.course_instance_date',
      );
  }
};

export const translateCourseInstanceLeadTimeXMode = (
  xMode: CourseInstanceLeadTimeXMode,
): string => {
  switch (xMode) {
    case CourseInstanceLeadTimeXMode.BOOKING_CREATED:
      return t(
        'components.CourseInstanceLeadTimeInsights.xMode.booking_created',
      );
    case CourseInstanceLeadTimeXMode.COURSE_INSTANCE_DATE:
      return t(
        'components.CourseInstanceLeadTimeInsights.xMode.course_instance_date',
      );
  }
};

export const translateCourseInstanceStatusYMode = (
  yMode: CourseInstanceStatusYMode,
  currencyCode?: string,
): string => {
  switch (yMode) {
    case CourseInstanceStatusYMode.NUMBER_OF_CLASSES:
      return t(
        'components.CourseInstanceStatusInsights.yMode.number_of_classes',
      );
    case CourseInstanceStatusYMode.REVENUE:
      return [
        t('components.CourseInstanceStatusInsights.yMode.revenue'),
        ...(currencyCode ? [`(${currencyCode})`] : []),
      ].join(' ');
  }
};

export const translateCourseInstanceLeadTimeYMode = (
  yMode: CourseInstanceLeadTimeYMode,
): string => {
  switch (yMode) {
    case CourseInstanceLeadTimeYMode.NUMBER_OF_CLASSES:
      return t(
        'components.CourseInstanceLeadTimeInsights.yMode.number_of_classes',
      );
  }
};

export const getCourseInstanceStatusGraphTooltipTitle = (
  xMode: CourseInstanceStatusXMode,
  yMode: CourseInstanceStatusYMode,
  startDate: Dayjs,
  endDate: Dayjs,
  bucketSizeOption: BucketSizeOption,
  currencyCode: string,
) => {
  let bucketSizeTranslation;
  let yModeTranslation;
  let xModeTranslation;
  let groupingTranslation;

  switch (bucketSizeOption) {
    case BucketSizeOption.Daily:
      bucketSizeTranslation = t(
        'components.InsightsFilters.BucketSizeOptions.daily',
      );
      break;
    case BucketSizeOption.Weekly:
      bucketSizeTranslation = t(
        'components.InsightsFilters.BucketSizeOptions.weekly',
      );
      break;
    case BucketSizeOption.Monthly:
      bucketSizeTranslation = t(
        'components.InsightsFilters.BucketSizeOptions.monthly',
      );
      break;
  }

  switch (yMode) {
    case CourseInstanceStatusYMode.REVENUE:
      yModeTranslation = t(
        'components.CourseInstanceStatusInsights.graphTooltip.yModeRevenue',
        { currencyCode: currencyCode },
      );
      groupingTranslation = t(
        'components.CourseInstanceStatusInsights.graphTooltip.yModeRevenueGrouping',
        { bucketSize: bucketSizeTranslation },
      );
      break;
    case CourseInstanceStatusYMode.NUMBER_OF_CLASSES:
      yModeTranslation = t(
        'components.CourseInstanceStatusInsights.graphTooltip.yModeClasses',
      );
      groupingTranslation = t(
        'components.CourseInstanceStatusInsights.graphTooltip.yModeClassDateGrouping',
        { bucketSize: bucketSizeTranslation },
      );
      break;
  }

  switch (xMode) {
    case CourseInstanceStatusXMode.BOOKING_CREATED:
      xModeTranslation = t(
        'components.CourseInstanceStatusInsights.graphTooltip.xModeSold',
      );
      break;
    case CourseInstanceStatusXMode.COURSE_INSTANCE_DATE:
      xModeTranslation = t(
        'components.CourseInstanceStatusInsights.graphTooltip.xModeCourseDate',
      );
      break;
  }

  const graphExplanationTranslation = (
    <Trans
      i18nKey="components.CourseInstanceStatusInsights.graphTooltip.graphExplanation"
      values={{
        bucketSize: bucketSizeTranslation,
        yMode: yModeTranslation,
        xMode: xModeTranslation,
        startDate: formatTooltipDate(startDate),
        endDate: formatTooltipDate(endDate),
      }}
    />
  );

  return (
    <div className="flex flex-col gap-2 whitespace-nowrap overflow-auto">
      <div>{graphExplanationTranslation}</div>
      <div>{groupingTranslation}</div>
      <div>
        <Trans i18nKey="components.CourseInstanceStatusInsights.graphTooltip.statusExplanation" />
      </div>
      <div>
        <Trans i18nKey="components.CourseInstanceStatusInsights.graphTooltip.totalSum" />
      </div>
    </div>
  );
};
