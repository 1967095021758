import {
  CaretUpFilled,
  FilterFilled,
  QuestionCircleFilled,
} from '@ant-design/icons';
import { Badge, Button, Tabs, Tooltip } from 'antd';
import debounce from 'lodash/debounce';
import dayjs, { Dayjs } from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';

import CourseInstanceAPI, {
  CourseInstanceApiFilter,
} from '../CourseInstanceAPI';
import ContentCard from '../../components/ContentCard';
import CourseInstanceCalendar from '../components/CourseInstanceCalendar';
import CourseInstanceFilter from '../components/CourseInstanceFilter';
import CourseInstanceResults from '../components/CourseInstanceResults';
import CourseInstanceViewInstructions from '../components/CourseInstanceViewInstructions';
import DateCourseInstancesModal from '../components/DateCourseInstancesModal';
import { useUser } from '../../user/userContext';
import {
  CalendarCourseInstanceInfo,
  CourseInstanceCardData,
  CourseInstanceStatus,
} from '../types/CourseInstance';
import PaginationMeta from '../../types/PaginationMeta';
import PaginationParams from '../../types/PaginationParams';
import { userHasPermission } from '../../user/userUtils';
import { Permission } from '../../user/types/Permission';

dayjs.extend(weekOfYear);

const CourseInstances: React.FC = () => {
  const [courseInstances, setCourseInstances] = useState<
    CourseInstanceCardData[]
  >([]);
  const [loadingCourseInstances, setLoadingCourseInstances] =
    useState<boolean>(false);
  const [loadingCalendarCourseInstances, setLoadingCalendarCourseInstances] =
    useState(false);
  const [isFilterVisible, setFilterVisible] = useState<boolean>(false);
  const [isFilterPristine, setFilterPristine] = useState<boolean>(true);
  const [filter, setFilter] = useState<CourseInstanceApiFilter>({});
  const [paginationParams, setPaginationParams] =
    useState<PaginationParams | null>({
      page: 1,
    });
  const [paginationMeta, setPaginationMeta] = useState<PaginationMeta | null>();
  const courseInstancesRef = useRef<CourseInstanceCardData[]>(courseInstances);
  courseInstancesRef.current = courseInstances;
  const [courseInstanceGroups, setCourseInstanceGroups] =
    useState<Array<{ title: string; rows: CourseInstanceCardData[] }>>();
  const [currentUser] = useUser();
  const [showInstructions, setShowInstructions] = useState<boolean>(false);
  const [calendarResults, setCalendarResults] =
    useState<CalendarCourseInstanceInfo[]>();
  const [dateString, setDateString] = useState<string>();
  const [selectedDay, setSelectedDay] = useState<Date | null>();

  const { t } = useTranslation();

  const fetchCalendarCourseInstances = useCallback(
    async (_filter?: CourseInstanceApiFilter) => {
      if (_filter?.completed) {
        _filter.startsAt = undefined;
        _filter.status = [CourseInstanceStatus.Confirmed];
        _filter.endsAt = dayjs().endOf('day').toISOString();
      }
      if (
        !userHasPermission(currentUser, Permission.COURSE_INSTANCE_READ_GLOBAL)
      ) {
        _filter!.status = [
          CourseInstanceStatus.Confirmed,
          CourseInstanceStatus.Ordered,
          CourseInstanceStatus.Preliminary,
        ];
      }
      setLoadingCalendarCourseInstances(true);

      if (_filter?.instructorIds?.includes(0)) {
        _filter = {
          ..._filter,
          instructorIds: _filter.instructorIds
            .filter((id) => id !== 0)
            .concat([null]),
        };
      }

      try {
        const { data } = await CourseInstanceAPI.getCourseInstanceCalendar({
          ..._filter,
          month: dayjs(dateString).format('YYYY-MM'),
        });
        setCalendarResults(data);
      } finally {
        setLoadingCalendarCourseInstances(false);
      }
    },
    [currentUser, dateString],
  );

  const fetchCourseInstances = useCallback(
    async (
      _filter?: CourseInstanceApiFilter,
      _paginationParams?: PaginationParams | null,
    ) => {
      if (_filter?.completed) {
        _filter.startsAt = undefined;
        _filter.status = [CourseInstanceStatus.Confirmed];
        _filter.endsAt = dayjs().endOf('day').toISOString();
      }
      if (
        !userHasPermission(currentUser, Permission.COURSE_INSTANCE_READ_GLOBAL)
      ) {
        _filter!.status = [
          CourseInstanceStatus.Confirmed,
          CourseInstanceStatus.Ordered,
          CourseInstanceStatus.Preliminary,
        ];
      }
      setLoadingCourseInstances(true);

      if (_filter?.instructorIds?.includes(0)) {
        _filter = {
          ..._filter,
          instructorIds: _filter.instructorIds
            .filter((id) => id !== 0)
            .concat([null]),
        };
      }

      try {
        const { data: res } = await CourseInstanceAPI.getCourseInstances(
          _filter ?? filter,
          _paginationParams,
        );
        setCourseInstances(courseInstancesRef.current.concat(res.data));
        courseInstancesRef.current = courseInstancesRef.current.concat(
          res.data,
        );
        setPaginationMeta(res.meta);
        setPaginationParams({ page: res.meta.page + 1 });
      } finally {
        setLoadingCourseInstances(false);
      }
    },
    [currentUser, filter],
  );

  const onTabChange = useCallback(
    (tab: string) => {
      if (tab === 'list') {
        setCourseInstances([]);
        setPaginationParams({ page: 1 });
        setPaginationMeta(null);
        fetchCourseInstances(filter, { page: 1 });
      } else {
        fetchCalendarCourseInstances(filter);
      }
    },
    [fetchCourseInstances, filter, fetchCalendarCourseInstances],
  );

  useEffect(() => {
    const weeks: {
      [key: string]: CourseInstanceCardData[];
    } = courseInstances.reduce(
      (acc: { [key: string]: CourseInstanceCardData[] }, courseInstance) => {
        const week = dayjs(courseInstance.startsAt).week();
        return {
          ...acc,
          [week]: (acc[week] ?? []).concat(courseInstance),
        };
      },
      {},
    );

    const groups = Object.keys(weeks).map((week) => ({
      title: t('views.CourseInstances.week', { weekNumber: week }),
      rows: weeks[week],
    }));

    const sortedGroups = groups.sort(
      (a, b) => +dayjs(a.rows[0].startsAt) - +dayjs(b.rows[0].startsAt),
    );

    setCourseInstanceGroups(sortedGroups);
  }, [courseInstances, t]);

  useEffect(() => {
    if (dateString && filter) {
      fetchCalendarCourseInstances(filter);
    }
  }, [dateString, fetchCalendarCourseInstances, filter]);

  /*
   * Debounce the call to fetchCourseInstances to prevent the infinite scroll component
   * from calling it multiple times before the state has finished updating.
   */
  const debounceFetchCourseInstances = useMemo(
    () =>
      debounce(
        (
          _filter?: CourseInstanceApiFilter,
          _paginationParams?: PaginationParams | null,
        ) => fetchCourseInstances(_filter, _paginationParams),
        10,
      ),
    [fetchCourseInstances],
  );

  const onFilterChange = useCallback(
    (filter: CourseInstanceApiFilter, pristine: boolean) => {
      const newPaginationParams = { page: 1 };
      setCourseInstances([]);
      setFilter(filter);
      setPaginationParams(newPaginationParams);
      setPaginationMeta(null);
      fetchCourseInstances(filter, newPaginationParams);
      fetchCalendarCourseInstances(filter);
      setFilterPristine(pristine);
      if (!pristine) {
        setFilterVisible(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const getLink = useCallback(
    (courseInstance: CourseInstanceCardData) => {
      if (userHasPermission(currentUser, Permission.BOOKING_ORDER_READ_LIST)) {
        return `/bestallningar/${courseInstance.orderId}`;
      } else {
        return `/kurstillfallen/${courseInstance.id}`;
      }
    },
    [currentUser],
  );

  const updateCourseInstance = useCallback(
    (id: number, newCourseInstance: CourseInstanceCardData) => {
      setCourseInstances(
        courseInstances.map((courseInstance) =>
          courseInstance.id === id ? newCourseInstance : courseInstance,
        ),
      );
    },
    [courseInstances],
  );

  const removeCourseInstanceFromList = useCallback(
    (id: number) => {
      setCourseInstances(
        courseInstances.filter((courseInstance) => courseInstance.id !== id),
      );
    },
    [courseInstances],
  );

  const removeCourseInstanceFromCalendar = useCallback((date: Dayjs) => {
    setCalendarResults((prevState) => {
      if (!prevState) {
        return prevState;
      }

      return prevState
        .map((calendarDate) => {
          if (dayjs(calendarDate.date).isSame(date)) {
            calendarDate.nBookings -= 1;
          }
          return calendarDate;
        })
        .filter((calendarDate) => calendarDate.nBookings > 0);
    });
  }, []);

  const tabItems = useMemo(
    () => [
      {
        key: 'calendar',
        label: t('common.calendar'),
        children: calendarResults && (
          <CourseInstanceCalendar
            setDateString={setDateString}
            fetchedEvents={calendarResults}
            onClickDay={setSelectedDay}
            loadingCourseInstances={loadingCalendarCourseInstances}
          />
        ),
      },
      {
        key: 'list',
        label: t('common.list'),
        children: (
          <div className="z-10 relative">
            <InfiniteScroll
              loadMore={() => {
                if (!loadingCourseInstances) {
                  setLoadingCourseInstances(true);
                  return debounceFetchCourseInstances(filter, {
                    ...paginationParams,
                    page: paginationParams?.page,
                  });
                }
              }}
              hasMore={
                !loadingCourseInstances &&
                (paginationMeta?.page ?? 1) < (paginationMeta?.lastPage ?? 1)
              }>
              <CourseInstanceResults
                courseInstances={courseInstanceGroups}
                loading={loadingCourseInstances}
                getLink={getLink}
                updateCourseInstance={updateCourseInstance}
                removeCourseInstanceFromList={removeCourseInstanceFromList}
                onFilterChange={() => onFilterChange(filter, isFilterPristine)}
              />
            </InfiniteScroll>
          </div>
        ),
      },
    ],
    [
      calendarResults,
      courseInstanceGroups,
      debounceFetchCourseInstances,
      filter,
      getLink,
      isFilterPristine,
      loadingCalendarCourseInstances,
      loadingCourseInstances,
      onFilterChange,
      paginationMeta?.lastPage,
      paginationMeta?.page,
      paginationParams,
      removeCourseInstanceFromList,
      t,
      updateCourseInstance,
    ],
  );

  return (
    <>
      <h2 className="grid grid-cols-2 items-center">
        {t('common.courseInstances')}
        <div className="flex justify-end">
          <Tooltip
            placement="top"
            title={t('views.CourseInstances.instructions')}>
            <Button
              className="mr-2"
              icon={<QuestionCircleFilled />}
              shape="circle"
              type="primary"
              onClick={() => setShowInstructions(true)}
            />
          </Tooltip>

          <Badge dot={!isFilterPristine}>
            <Tooltip placement="top" title={t('common.filter')}>
              <Button
                type="primary"
                shape="circle"
                icon={isFilterVisible ? <CaretUpFilled /> : <FilterFilled />}
                onClick={() => setFilterVisible((prevState) => !prevState)}
              />
            </Tooltip>
          </Badge>
        </div>
      </h2>
      <CourseInstanceFilter
        visible={isFilterVisible}
        onChange={onFilterChange}
      />

      <ContentCard>
        <Tabs
          defaultActiveKey="calendar"
          size="large"
          onChange={(view) => {
            onTabChange(view);
          }}
          items={tabItems}
        />
      </ContentCard>
      {selectedDay && filter && (
        <DateCourseInstancesModal
          filter={filter}
          date={dayjs(selectedDay)}
          onCancel={() => setSelectedDay(null)}
          onCourseInstanceDelete={removeCourseInstanceFromCalendar}
        />
      )}
      <CourseInstanceViewInstructions
        visible={showInstructions}
        onCancel={() => setShowInstructions(false)}
      />
    </>
  );
};

export default CourseInstances;
