import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import dayjs from 'dayjs';
import {
  CourseInstanceStatus,
  CourseInstanceStatusXMode,
  CourseInstanceStatusYMode,
} from '../insightsV2/types/CourseInstanceStatus';
import {
  CourseInstanceLeadTime,
  CourseInstanceLeadTimeXMode,
  CourseInstanceLeadTimeYMode,
} from '../insightsV2/types/CourseInstanceLeadTime';
import Market from '../market/types/Market';
import { SubsidiaryCompanyGoal } from '../subsidiaryCompany/types/SubsidiaryCompany';

const InsightsContext = React.createContext<{
  market: Market | undefined;
  setMarket: (market: Market | undefined) => void;
  subsidiaryCompanyIds: number[] | undefined;
  setSubsidiaryCompanyIds: (ids: number[] | undefined) => void;
  clientCompanyIds: number[] | undefined;
  setClientCompanyIds: (ids: number[] | undefined) => void;
  instructorIds: number[] | undefined;
  setInstructorIds: (ids: number[] | undefined) => void;
  courseIds: number[] | undefined;
  setCourseIds: (ids: number[] | undefined) => void;
  responsibleBookerIds: number[] | undefined;
  setResponsibleBookerIds: (ids: number[] | undefined) => void;
  invoiceRecipientIds: number[] | undefined;
  setInvoiceRecipientIds: (ids: number[] | undefined) => void;
  startDate: dayjs.Dayjs;
  setStartDate: (startDate: dayjs.Dayjs) => void;
  endDate: dayjs.Dayjs;
  setEndDate: (endDate: dayjs.Dayjs) => void;
  statusXMode: CourseInstanceStatusXMode;
  setStatusXMode: (xMode: CourseInstanceStatusXMode) => void;
  statusYMode: CourseInstanceStatusYMode;
  setStatusYMode: (yMode: CourseInstanceStatusYMode) => void;
  statusBucketSize: number;
  setStatusBucketSize: (statusBucketSize: number) => void;
  selectedStatus: CourseInstanceStatus | null;
  setSelectedStatus: (
    selectedCourseStatus: CourseInstanceStatus | null,
  ) => void;
  leadTimeXMode: CourseInstanceLeadTimeXMode;
  setLeadTimeXMode: (xMode: CourseInstanceLeadTimeXMode) => void;
  leadTimeYMode: CourseInstanceLeadTimeYMode;
  setLeadTimeYMode: (yMode: CourseInstanceLeadTimeYMode) => void;
  leadTimeBucketSize: number;
  setLeadTimeBucketSize: (leadTimeBucketSize: number) => void;
  selectedLeadTime: CourseInstanceLeadTime | null;
  setSelectedLeadTime: (
    selectedLeadTime: CourseInstanceLeadTime | null,
  ) => void;
  goals: SubsidiaryCompanyGoal[] | undefined;
  setGoals: (goals: SubsidiaryCompanyGoal[] | undefined) => void;
}>(undefined!); // eslint-disable-line @typescript-eslint/no-non-null-assertion

export const useInsights = () => useContext(InsightsContext);

type InsightsContextProviderProps = PropsWithChildren;

const InsightsContextProvider: React.FC<InsightsContextProviderProps> = ({
  children,
}) => {
  const [market, setMarket] = useState<Market | undefined>(undefined);
  const [subsidiaryCompanyIds, setSubsidiaryCompanyIds] = useState<
    number[] | undefined
  >(undefined);
  const [clientCompanyIds, setClientCompanyIds] = useState<
    number[] | undefined
  >(undefined);
  const [instructorIds, setInstructorIds] = useState<number[] | undefined>(
    undefined,
  );
  const [courseIds, setCourseIds] = useState<number[] | undefined>(undefined);
  const [responsibleBookerIds, setResponsibleBookerIds] = useState<
    number[] | undefined
  >(undefined);
  const [invoiceRecipientIds, setInvoiceRecipientIds] = useState<
    number[] | undefined
  >(undefined);

  const [startDate, setStartDate] = useState<dayjs.Dayjs>(
    dayjs().subtract(1, 'month'),
  );
  const [endDate, setEndDate] = useState<dayjs.Dayjs>(dayjs());

  const [statusXMode, setStatusXMode] = useState<CourseInstanceStatusXMode>(
    CourseInstanceStatusXMode.BOOKING_CREATED,
  );
  const [statusYMode, setStatusYMode] = useState<CourseInstanceStatusYMode>(
    CourseInstanceStatusYMode.NUMBER_OF_CLASSES,
  );
  const [statusBucketSize, setStatusBucketSize] = useState<number>(7);
  const [selectedStatus, setSelectedStatus] =
    useState<CourseInstanceStatus | null>(null);

  const [leadTimeXMode, setLeadTimeXMode] =
    useState<CourseInstanceLeadTimeXMode>(
      CourseInstanceLeadTimeXMode.BOOKING_CREATED,
    );
  const [leadTimeYMode, setLeadTimeYMode] =
    useState<CourseInstanceLeadTimeYMode>(
      CourseInstanceLeadTimeYMode.NUMBER_OF_CLASSES,
    );
  const [leadTimeBucketSize, setLeadTimeBucketSize] = useState<number>(7);
  const [selectedLeadTime, setSelectedLeadTime] =
    useState<CourseInstanceLeadTime | null>(null);

  const [goals, setGoals] = useState<SubsidiaryCompanyGoal[]>();

  useEffect(() => {
    if (market === undefined) {
      setStatusYMode(CourseInstanceStatusYMode.NUMBER_OF_CLASSES);
    }
  }, [market]);

  useEffect(() => {
    if (subsidiaryCompanyIds?.length !== 1) {
      setGoals(undefined);
    }
  }, [subsidiaryCompanyIds]);

  return (
    <InsightsContext.Provider
      value={{
        market,
        setMarket,
        subsidiaryCompanyIds,
        setSubsidiaryCompanyIds,
        clientCompanyIds,
        setClientCompanyIds,
        instructorIds,
        setInstructorIds,
        courseIds,
        setCourseIds,
        responsibleBookerIds,
        setResponsibleBookerIds,
        invoiceRecipientIds,
        setInvoiceRecipientIds,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        statusXMode,
        setStatusXMode,
        statusYMode,
        setStatusYMode,
        statusBucketSize,
        setStatusBucketSize,
        selectedStatus,
        setSelectedStatus,
        leadTimeXMode,
        setLeadTimeXMode,
        leadTimeYMode,
        setLeadTimeYMode,
        leadTimeBucketSize,
        setLeadTimeBucketSize,
        selectedLeadTime,
        setSelectedLeadTime,
        goals,
        setGoals,
      }}>
      {children}
    </InsightsContext.Provider>
  );
};

export default InsightsContextProvider;
