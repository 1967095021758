import React from 'react';
import {
  ComputedDatum,
  CustomLayerProps,
  Point,
  ResponsiveLine,
} from '@nivo/line';
import { useInsights } from '../../context/insights';
import { linearGradientDef } from '@nivo/core';
import { Card, Row } from 'antd';
import { twMerge } from 'tailwind-merge';
import {
  CourseInstanceStatus,
  CourseInstanceStatusColor,
  CourseInstanceStatusData,
  CourseInstanceStatusYMode,
} from '../types/CourseInstanceStatus';
import {
  translateCourseInstanceStatusXMode,
  translateCourseInstanceStatusYMode,
  translateGraphCourseInstanceStatus,
} from '../utils/translate';
import NoCourseInstances from './NoCourseInstances';
import dayjs from 'dayjs';
import {
  computeTickValues,
  getDateRangeFormat,
  renderLightTick,
} from '../utils/axis';
import { bucketGraphData, insertBetween } from '../utils/bucketSize';
import { useTranslation } from 'react-i18next';

type CourseInstanceStatusGraphProps = {
  data?: CourseInstanceStatusData;
};

const GOAL_ID = 'goal';
const GOAL_COLOR = '#7E848D';

const CourseInstanceStatusGraph: React.FC<CourseInstanceStatusGraphProps> = ({
  data,
}) => {
  const { t } = useTranslation();
  const {
    market,
    startDate,
    endDate,
    statusXMode: xMode,
    statusYMode: yMode,
    statusBucketSize,
    selectedStatus,
    goals,
  } = useInsights();

  if (data === undefined) {
    return <NoCourseInstances />;
  }

  const showRevenue = yMode === CourseInstanceStatusYMode.REVENUE;

  const formatter = Intl.NumberFormat('en', {
    maximumSignificantDigits: 2,
    notation: 'compact',
  });

  const bucketedData = bucketGraphData(data.graphs, statusBucketSize);
  const tickValues = computeTickValues(bucketedData[0].data.map((d) => d.x));
  const dateRangeFormat = getDateRangeFormat(startDate, endDate);

  const DashedGoalLine = ({ series, lineGenerator }: CustomLayerProps) => {
    const serie = series.find((s) => s.id === GOAL_ID);

    if (!serie) {
      return;
    }

    const serieDataWithStartAndEndOfMonthBreakers = insertBetween(
      serie.data,
      (a: ComputedDatum, b: ComputedDatum) => {
        const aDate = dayjs(a.data.x);
        const bDate = dayjs(b.data.x);

        if (aDate.month() === bDate.month()) {
          return [];
        }

        const dayDiff = bDate.diff(aDate) / (1000 * 60 * 60 * 24);
        const dayIntervalX = (b.position.x - a.position.x) / dayDiff;

        const endOfMonth = {
          data: {
            x: aDate.endOf('month').toDate(),
            y: a.data.y,
          },
          position: {
            x:
              a.position.x +
              dayIntervalX * (aDate.daysInMonth() - aDate.date()),
            y: a.position.y,
          },
        };

        const startOfMonth = {
          data: {
            x: bDate.startOf('month').toDate(),
            y: b.data.y,
          },
          position: {
            x: b.position.x - dayIntervalX * (bDate.date() - 1),
            y: b.position.y,
          },
        };

        return [endOfMonth, startOfMonth];
      },
    );

    return (
      <path
        d={
          lineGenerator(
            serieDataWithStartAndEndOfMonthBreakers.map((d) => ({
              x: d.position.x,
              y: d.position.y,
            })),
          ) ?? ''
        }
        fill="none"
        stroke={GOAL_COLOR}
        style={{
          strokeDasharray: '8, 6',
          strokeWidth: 3,
        }}
      />
    );
  };

  const hasGoals = bucketedData[0].data.some((d) => {
    const activeMonth = dayjs(d.x).format('YYYY-MM');
    const goal = goals?.find((g) => g.activeMonth === activeMonth);
    return (
      (showRevenue ? goal?.goalRevenue : goal?.goalNumberOfCourseInstances) !==
      undefined
    );
  });

  const getGraphTooltipStatusRowClassName = (
    id: CourseInstanceStatus,
    point: Point,
  ) => {
    return twMerge(
      'text-sm items-center gap-2',
      id === CourseInstanceStatus.TOTAL && 'font-semibold',
      (point.serieId === GOAL_ID ||
        (!hasGoals && id === CourseInstanceStatus.TOTAL)) &&
        'mb-2',
    );
  };

  return (
    <ResponsiveLine
      data={[
        ...bucketedData,
        ...(hasGoals
          ? [
              {
                id: GOAL_ID,
                label: t('views.InsightsV2.goalLabel'),
                data: bucketedData[0].data.map((d) => {
                  const activeMonth = dayjs(d.x).format('YYYY-MM');
                  const goal = goals?.find(
                    (g) => g.activeMonth === activeMonth,
                  );
                  return {
                    x: d.x,
                    y:
                      (showRevenue
                        ? goal?.goalRevenue
                        : goal?.goalNumberOfCourseInstances) ?? 0,
                  };
                }),
                color: GOAL_COLOR,
              },
            ]
          : []),
      ]}
      margin={{ top: 10, right: 40, bottom: 50, left: 56 }}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 0,
        max: 'auto',
      }}
      curve="monotoneX"
      lineWidth={2.5}
      axisBottom={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        tickValues,
        legend: translateCourseInstanceStatusXMode(xMode),
        legendOffset: 36,
        legendPosition: 'middle',
        truncateTickAt: 0,
        format: (v: Date) => dayjs(v).format('D MMM'),
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        legend: translateCourseInstanceStatusYMode(
          yMode,
          market?.defaultCurrency.code,
        ),
        legendOffset: -48,
        legendPosition: 'middle',
        truncateTickAt: 0,
        renderTick: (props) =>
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          renderLightTick(props, (value: any) => {
            if (showRevenue) {
              return formatter.format(value);
            } else if (value % 1 === 0) {
              return value;
            }
          }),
      }}
      enableArea={true}
      enableGridX={false}
      enablePoints={false}
      enableSlices="x"
      enableCrosshair={true}
      enableTouchCrosshair={true}
      useMesh={true}
      layers={[
        'grid',
        'markers',
        'axes',
        'areas',
        'crosshair',
        'lines',
        'slices',
        'points',
        'mesh',
        'legends',
        DashedGoalLine,
      ]}
      colors={[
        ...data.graphs.map((g) => {
          const color =
            g.id === CourseInstanceStatus.TOTAL ? `${g.color}00` : g.color;

          if (selectedStatus === null) {
            return color;
          }

          // If 'TOTAL' is selected we want to make its color stronger and decrease the colors of the other lines
          // If some other line is selected we want to decrease the color of all the others

          if (g.id === selectedStatus && g.id === CourseInstanceStatus.TOTAL) {
            return `${color.substring(0, 7)}77`;
          }

          if (g.id !== selectedStatus && g.id !== CourseInstanceStatus.TOTAL) {
            return `${color.substring(0, 7)}22`;
          }

          return color;
        }),
        '#00000000', // Make goal line invisible
      ]}
      defs={[
        linearGradientDef('gradient', [
          {
            offset: 0,
            color: CourseInstanceStatusColor[CourseInstanceStatus.TOTAL],
            opacity:
              selectedStatus && selectedStatus !== CourseInstanceStatus.TOTAL
                ? 0.25
                : 0.53,
          },
          {
            offset: 100,
            color: CourseInstanceStatusColor[CourseInstanceStatus.TOTAL],
            opacity: 0,
          },
        ]),
        linearGradientDef('transparent', [
          { offset: 0, color: 'inherit', opacity: 0 },
        ]),
      ]}
      fill={[
        {
          match: (d) => d.id === CourseInstanceStatus.TOTAL,
          id: 'gradient',
        },
        { match: '*', id: 'transparent' },
      ]}
      sliceTooltip={({ slice: { points } }) => {
        const bucketStartDate = dayjs(points[0].data.xFormatted);
        const bucketEndDate = dayjs(points[0].data.xFormatted).add(
          statusBucketSize - 1,
          'day',
        );
        const bucketEndDateCeiled = bucketEndDate.isAfter(endDate)
          ? endDate
          : bucketEndDate;

        return (
          <Card className="shadow" bodyStyle={{ padding: '12px' }}>
            {points
              .toReversed()
              // Place 'goal' right after 'total'
              .sort((p1, p2) => {
                if (
                  p1.serieId === GOAL_ID &&
                  p2.serieId !== CourseInstanceStatus.TOTAL
                ) {
                  return -1;
                }
                if (
                  p2.serieId === GOAL_ID &&
                  p1.serieId !== CourseInstanceStatus.TOTAL
                ) {
                  return 1;
                }
                return 0;
              })
              .map((p) => {
                const id =
                  CourseInstanceStatus[
                    p.serieId as keyof typeof CourseInstanceStatus
                  ];
                const icon = () => {
                  if (p.serieId === GOAL_ID) {
                    return (
                      <div className="flex flex-row items-center gap-1">
                        <div
                          className="h-1 w-[6px] rounded-full"
                          style={{
                            backgroundColor: GOAL_COLOR,
                          }}
                        />
                        <div
                          className="h-1 w-[6px] rounded-full"
                          style={{
                            backgroundColor: GOAL_COLOR,
                          }}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div
                        className="h-1 w-4 rounded-full"
                        style={{
                          backgroundColor: p.serieColor,
                        }}
                      />
                    );
                  }
                };

                return (
                  <>
                    <Row
                      key={p.id}
                      className={getGraphTooltipStatusRowClassName(id, p)}>
                      {id !== CourseInstanceStatus.TOTAL && icon()}
                      <span className="mr-4">
                        {p.serieId === GOAL_ID
                          ? t('views.InsightsV2.goalLabel')
                          : translateGraphCourseInstanceStatus(id, yMode)}
                      </span>
                      <span className="ml-auto">
                        {showRevenue
                          ? formatter.format(p.data.yFormatted as number)
                          : p.data.yFormatted}
                        <br />
                      </span>
                    </Row>
                  </>
                );
              })}
            <Row className="mt-2 flex justify-between">
              <div className="italic">
                {statusBucketSize > 1
                  ? [
                      bucketStartDate.format(dateRangeFormat),
                      bucketEndDateCeiled.format(dateRangeFormat),
                    ].join(' - ')
                  : bucketStartDate.format(dateRangeFormat)}
              </div>
              {yMode === CourseInstanceStatusYMode.REVENUE && (
                <div className="ml-2">{market?.defaultCurrency.code ?? ''}</div>
              )}
            </Row>
          </Card>
        );
      }}
    />
  );
};

export default CourseInstanceStatusGraph;
