import React, { useCallback, useEffect, useState } from 'react';
import { Card, Col, Divider, Row, Select, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useInsights } from '../../context/insights';
import {
  CourseInstanceStatus,
  CourseInstanceStatusStatistic,
  CourseInstanceStatusXMode,
  CourseInstanceStatusYMode,
  CourseInstanceStatusDataResponse,
  CourseInstanceStatusData,
  CourseInstanceStatusColor,
  mapCourseInstanceStatisticToCourseInstanceStatus,
} from '../types/CourseInstanceStatus';
import StatisticCard from './StatisticCard';
import TotalNumberOfCourses from '../../resources/img/totalNumberOfCourses.svg';
import Invoiced from '../../resources/img/invoiced.svg';
import Confirmed from '../../resources/img/confirmed.svg';
import Preliminary from '../../resources/img/preliminary.svg';
import WithoutPrice from '../../resources/img/withoutPrice.svg';
import CourseInstanceStatusGraph from './CourseInstanceStatusGraph';
import CourseInstanceStatusDonut from './CourseInstanceStatusDonut';
import dayjs from 'dayjs';
import InsightsV2API from '../InsightsV2API';
import {
  translateCourseInstanceStatus,
  translateCourseInstanceStatusStatistic,
  getCourseInstanceStatusStatisticTooltipTitle,
  translateCourseInstanceStatusXMode,
  translateCourseInstanceStatusYMode,
  getCourseInstanceStatusGraphTooltipTitle,
} from '../utils/translate';
import { CourseInstanceStatusLegend } from './CourseInstanceStatusLegend';
import { CourseInstanceDistribution } from './CourseInstanceDistribution';
import { CourseInstanceStatusStack } from './CourseInstanceStatusStack';
import { updateBucketSize } from '../utils/bucketSize';
import BucketSizePicker from './BucketSizePicker';
import { getDateRangeFormat } from '../utils/axis';
import { CloseCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';

const cleanUpData = (
  data: CourseInstanceStatusDataResponse,
): CourseInstanceStatusData => {
  const graphs = data.graphs
    .map((g) => ({
      id: g.id,
      label: translateCourseInstanceStatus(g.id as CourseInstanceStatus),
      data: g.data.map((d, idx) => ({
        x: dayjs(data.from, 'YYYY-MM-DD').add(idx, 'day').toDate(),
        y: d,
      })),
      color:
        CourseInstanceStatusColor[
          g.id as keyof typeof CourseInstanceStatusColor
        ],
    }))
    .sort(
      (g1, g2) =>
        Object.keys(CourseInstanceStatus).indexOf(g1.id) -
        Object.keys(CourseInstanceStatus).indexOf(g2.id),
    );

  return {
    graphs,
    statistics: data.statistics,
  };
};

const CourseInstanceStatusInsights: React.FC = () => {
  const { t } = useTranslation();

  const {
    market,
    subsidiaryCompanyIds,
    clientCompanyIds,
    instructorIds,
    courseIds,
    responsibleBookerIds,
    invoiceRecipientIds,
    startDate,
    endDate,
    statusXMode: xMode,
    setStatusXMode: setXMode,
    statusYMode: yMode,
    setStatusYMode: setYMode,
    statusBucketSize,
    setStatusBucketSize,
    selectedStatus,
    setSelectedStatus,
    setGoals,
  } = useInsights();

  const [data, setData] = useState<CourseInstanceStatusData>();
  const [loadingData, setLoadingData] = useState(false);
  const [distributionIndex, setDistributionIndex] = useState(0);
  const [withoutPriceClassCount, setWithoutPriceClassCount] = useState(0);

  const fetchCourseInstanceStatusData = useCallback(async () => {
    setLoadingData(true);

    setData((prevData) => {
      if (prevData) {
        return {
          ...prevData,
          graphs: prevData.graphs.map((g) => ({
            ...g,
            data: g.data.map((d) => ({ ...d, y: 0 })),
          })),
        };
      }
      return prevData;
    });

    try {
      const { data } = await InsightsV2API.getCourseInstanceStatusData(
        xMode,
        yMode,
        startDate,
        endDate,
        market?.id,
        subsidiaryCompanyIds,
        clientCompanyIds,
        instructorIds,
        courseIds,
        responsibleBookerIds,
        invoiceRecipientIds,
      );
      setData(cleanUpData(data));
      updateBucketSize(dayjs(data.from), dayjs(data.to), setStatusBucketSize);
    } catch {
      setData(undefined);
    } finally {
      setLoadingData(false);
    }
  }, [
    clientCompanyIds,
    courseIds,
    endDate,
    instructorIds,
    invoiceRecipientIds,
    market?.id,
    responsibleBookerIds,
    setStatusBucketSize,
    startDate,
    subsidiaryCompanyIds,
    xMode,
    yMode,
  ]);

  const fetchSubsidiaryCompanyGoals = useCallback(async () => {
    if (subsidiaryCompanyIds?.length !== 1) {
      return;
    }

    try {
      const { data } = await InsightsV2API.getSubsidiaryCompanyGoals(
        subsidiaryCompanyIds[0],
        startDate,
        endDate,
      );
      setGoals(data);
    } catch {
      setGoals(undefined);
    }
  }, [endDate, setGoals, startDate, subsidiaryCompanyIds]);

  useEffect(() => {
    fetchCourseInstanceStatusData();
  }, [fetchCourseInstanceStatusData]);

  useEffect(() => {
    fetchSubsidiaryCompanyGoals();
  }, [fetchSubsidiaryCompanyGoals]);

  const getSvgForStatistic = (statistic: CourseInstanceStatusStatistic) => {
    switch (statistic) {
      case CourseInstanceStatusStatistic.TOTAL:
        return TotalNumberOfCourses;
      case CourseInstanceStatusStatistic.PRELIMINARY:
        return Preliminary;
      case CourseInstanceStatusStatistic.CONFIRMED:
        return Confirmed;
      case CourseInstanceStatusStatistic.INVOICED:
        return Invoiced;
      case CourseInstanceStatusStatistic.WITHOUT_PRICE:
        return WithoutPrice;
      case CourseInstanceStatusStatistic.CANCELED:
        return Preliminary;
    }
  };

  const getCourseInstanceStatusTooltipTitle = (
    statistic: CourseInstanceStatusStatistic,
    value: number,
  ) => {
    return getCourseInstanceStatusStatisticTooltipTitle(
      statistic,
      xMode,
      value,
      startDate,
      endDate,
    );
  };

  const statistics = Object.entries(data?.statistics ?? [])
    .map((e) => {
      const statistic = e[0] as CourseInstanceStatusStatistic;

      const statisticInformation = {
        id: statistic,
        title: translateCourseInstanceStatusStatistic(statistic),
        value: e[1],
        tooltipTitle: getCourseInstanceStatusTooltipTitle(statistic, e[1]),
        svg: undefined as string | undefined,
        icon: undefined as React.ReactNode,
      };

      // Use antd icon for canceled status statistic
      if (statistic === CourseInstanceStatusStatistic.CANCELED) {
        statisticInformation.icon = (
          <div className="bg-courseInstanceCancelledLight w-[22px] h-[22px] flex justify-center rounded">
            <CloseCircleOutlined className="text-courseInstanceCancelledDark" />
          </div>
        );
      } else {
        statisticInformation.svg = getSvgForStatistic(statistic);
      }

      return statisticInformation;
    })
    .sort(
      (s1, s2) =>
        Object.values(CourseInstanceStatusStatistic).indexOf(s1.id) -
        Object.values(CourseInstanceStatusStatistic).indexOf(s2.id),
    );

  useEffect(() => {
    setWithoutPriceClassCount(
      statistics.find(
        (stat) => stat.id === CourseInstanceStatusStatistic.WITHOUT_PRICE,
      )?.value ?? 0,
    );
  }, [statistics]);

  const distributionChildren = [
    <CourseInstanceStatusDonut key={0} data={data} />,
    <CourseInstanceStatusStack key={1} data={data} />,
  ];

  return (
    <Card>
      <div className="flex flex-col">
        <Row>
          <Col className="flex-1">
            <Row className="text-black text-xl font-semibold">
              {t('components.CourseInstanceStatusInsights.title')}
            </Row>
            <Row className="w-full text-grayMedium items-center gap-1">
              <span className="font-thin">
                {t('views.InsightsV2.interval')}
              </span>

              <span className="fond-medium">
                {t('views.InsightsV2.dateRange', {
                  start: startDate.format(
                    getDateRangeFormat(startDate, endDate),
                  ),
                  end: endDate.format(getDateRangeFormat(startDate, endDate)),
                })}
              </span>

              <span className="font-thin">{t('views.InsightsV2.with')}</span>

              <Select
                value={xMode}
                onChange={setXMode}
                className="mx-1"
                popupMatchSelectWidth={false}>
                {Object.values(CourseInstanceStatusXMode).map((xMode) => (
                  <Select.Option key={xMode} value={xMode}>
                    {translateCourseInstanceStatusXMode(xMode).toLowerCase()}
                  </Select.Option>
                ))}
              </Select>

              <span className="font-thin">{t('views.InsightsV2.shownIn')}</span>

              <Select
                value={yMode}
                onChange={setYMode}
                className="mx-1"
                popupMatchSelectWidth={false}>
                {Object.values(CourseInstanceStatusYMode).map((yMode) => {
                  const revenueOptionDisabled =
                    yMode === CourseInstanceStatusYMode.REVENUE &&
                    market === undefined;

                  return (
                    <Select.Option
                      key={yMode}
                      value={yMode}
                      disabled={revenueOptionDisabled}>
                      <Tooltip
                        {...(revenueOptionDisabled && {
                          title: t(
                            'views.InsightsV2.mustSelectMarketToShowRevenue',
                          ),
                        })}>
                        {translateCourseInstanceStatusYMode(
                          yMode,
                        ).toLowerCase()}
                      </Tooltip>
                    </Select.Option>
                  );
                })}
              </Select>

              <Divider type="vertical" className="h-[20px] mx-4" />

              <BucketSizePicker
                bucketSize={statusBucketSize}
                setBucketSize={setStatusBucketSize}
              />
            </Row>
          </Col>
        </Row>

        <Row className="flex flex-row justify-between items-center mt-8 w-[70%]">
          <Col>{data && <CourseInstanceStatusLegend data={data} />}</Col>
          <Col className="mr-12">
            <Tooltip
              overlayStyle={{ maxWidth: 'none' }}
              title={getCourseInstanceStatusGraphTooltipTitle(
                xMode,
                yMode,
                startDate,
                endDate,
                statusBucketSize,
                market?.defaultCurrency.code ?? '',
              )}>
              <QuestionCircleOutlined className="text-lg text-gray-600 cursor-pointer" />
            </Tooltip>
          </Col>
        </Row>

        <Row className="flex justify-end mb-1 -mt-2">
          <Tooltip
            overlayInnerStyle={{ width: '340px' }}
            title={getCourseInstanceStatusTooltipTitle(
              CourseInstanceStatusStatistic.WITHOUT_PRICE,
              withoutPriceClassCount,
            )}>
            <Col className="text-gray-500 flex justify-center w-[30%] pl-4">
              <p className="mr-1">
                {t(
                  'components.CourseInstanceStatusInsights.classesWithoutPrice',
                )}
                :
              </p>
              {withoutPriceClassCount}
            </Col>
          </Tooltip>
        </Row>

        <Row gutter={32}>
          <Col className="w-[70%] h-[400px]">
            <CourseInstanceStatusGraph data={data} />
          </Col>

          {data && (
            <Col className="w-[30%] h-[400px]">
              <CourseInstanceDistribution
                isStart={distributionIndex === 0}
                isEnd={distributionIndex === distributionChildren.length - 1}
                previous={() => {
                  if (distributionIndex > 0) {
                    setDistributionIndex(distributionIndex - 1);
                  }
                }}
                next={() => {
                  if (distributionIndex < distributionChildren.length - 1) {
                    setDistributionIndex(distributionIndex + 1);
                  }
                }}>
                {distributionChildren[distributionIndex]}
              </CourseInstanceDistribution>
            </Col>
          )}
        </Row>

        <Row gutter={16} className="items-stretch mt-8">
          {statistics
            .filter(
              (stat) => stat.id !== CourseInstanceStatusStatistic.WITHOUT_PRICE,
            )
            .map((stat, idx) => {
              const status = mapCourseInstanceStatisticToCourseInstanceStatus(
                stat.id,
              );
              return (
                <Col key={idx} className="flex-1">
                  <StatisticCard
                    title={stat.title}
                    tooltipTitle={stat.tooltipTitle}
                    value={stat.value}
                    icon={stat.icon}
                    svg={stat.svg}
                    selected={
                      selectedStatus !== undefined &&
                      mapCourseInstanceStatisticToCourseInstanceStatus(
                        stat.id,
                      ) === selectedStatus
                    }
                    {...(status !== undefined && {
                      setSelectedOnHover: () => setSelectedStatus(status),
                    })}
                    loading={loadingData}
                  />
                </Col>
              );
            })}
        </Row>
      </div>
    </Card>
  );
};

export default CourseInstanceStatusInsights;
